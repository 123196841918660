import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  IconButton,
} from '@material-ui/core'
import { createStyles, makeStyles } from '@material-ui/core/styles'
import CloseIcon from '@material-ui/icons/Close'
import { graphql, useStaticQuery } from 'gatsby'
import parse from 'html-react-parser'
import React, { FC, useEffect, useState } from 'react'
import { find } from 'remeda'
import { StringParam, useQueryParam } from 'use-query-params'

const useStyles = makeStyles((theme) =>
  createStyles({
    closeButton: {
      position: 'absolute',
      right: 8,
      top: 8,
      color: theme.palette.grey[500],
    },
    content: {
      '& p, img': {
        marginBottom: 16,
      },
    },
    backdrop: {
      backgroundColor: 'rgba(255, 255, 2555, 0.5)',
    },
  }),
)

const InitialModal: FC = () => {
  const classes = useStyles()
  const [m] = useQueryParam('m', StringParam)

  const data = useStaticQuery<GatsbyTypes.InitialModalQuery>(graphql`
    query InitialModal {
      allContentstackModalInitial {
        totalCount
        nodes {
          title
          value
          content
          uid
          locale
        }
      }
    }
  `)

  const getCurrentModal = (param: string | null | undefined) =>
    find(data.allContentstackModalInitial.nodes, ({ value }) => value === param)

  const [currentModal, setCurrentModal] = useState(getCurrentModal(m))

  useEffect(() => {
    if (m) {
      setCurrentModal(getCurrentModal(m))
    }
  }, [m])

  const [open, setOpen] = useState(Boolean(currentModal))

  const onClose = () => setOpen(false)

  return (
    <Dialog
      BackdropProps={{
        className: classes.backdrop,
      }}
      TransitionProps={{
        onExited: () => {
          setCurrentModal(undefined)
          const url = new URL(window.location.toString())
          url.searchParams.delete('m')
          window.history.pushState(null, '', url.toString())
        },
      }}
      aria-label={currentModal?.title}
      closeAfterTransition
      onClose={onClose}
      open={open}
      transitionDuration={{ enter: 500, exit: 500 }}
    >
      <DialogContent className={classes.content}>
        <IconButton className={classes.closeButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>
        {currentModal?.content ? parse(currentModal.content) : null}
      </DialogContent>
      <DialogActions>
        <Button autoFocus focusRipple={false} onClick={onClose}>
          Continue
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default InitialModal
